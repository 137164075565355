import React from 'react';
import { Dropdown } from 'react-bootstrap';
import LangSelector from './LangSelector';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/auth';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedOut: false
    }
  }

  logout = async () => {
    await localStorage.removeItem('access_token');
    await localStorage.removeItem('role');
    this.setState({
      loggedOut: true
    });
    window.location.reload();
  }

  render () {
    return (
      <nav className="main-header navbar navbar-expand navbar-white navbar-light">
        <ul className="navbar-nav">
          <li className="nav-item">
            <button className="nav-link btn-link" data-widget="pushmenu"><i className="fas fa-bars"></i></button>
          </li>
        </ul>
        <div className="navbar-nav ml-auto">
          <LangSelector />
          {
            this.props.loggedIn && (
              <Dropdown>
                <Dropdown.Toggle>
                  <i className='fa fa-user'> </i>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">Profile</Dropdown.Item>
                  <Dropdown.Item onClick={this.logout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
          }
        </div>
      </nav>
    );
  }
}


const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn
  }
};


export default connect(mapStateToProps, {logout})(Header);
