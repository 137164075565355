import './App.css';
import Header from './components/partials/header';
import SideBar from './components/partials/sidebar';
import { Routes, Route } from "react-router-dom";
import Patient from './components/pages/Patient/Patient';
import Dashboard from './components/pages/Dashboard/Dashboard';
import ShowPatientElement from './components/pages/Patient/ShowPatientElement';
import Reminder from './components/pages/Reminder/Reminder';
import Login from './components/pages/Login';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import AuthRoute  from './components/instances/Router/AuthRoute';
import SmsLog from './components/pages/Sms/SmsLog';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VoiceList from './components/pages/Voice/VoiceList';
import UserList from './components/pages/User/UserList';
import Recharge from './components/pages/Recharge/Recharge';
import Downloads from './components/pages/Downloads/Downloads';
import AddUser from './components/pages/User/AddUser';
import EditUser from './components/pages/User/EditUser';
import React, { useEffect, useState } from "react";
import { useIdleTimer } from 'react-idle-timer'

function logout(){
    var loggedIn = localStorage.getItem('access_token') || null;

    if(loggedIn){
      localStorage.removeItem('access_token');
      localStorage.removeItem('role');
      window.location.reload();
    }
}


function App() {
  
  const handleOnActive = event => {
    console.log("");
  }

  const handleOnAction = event => {
    var nothing = 0;
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: logout,
    onActive: handleOnActive,
    onAction: handleOnAction,
    crossTab: true,
    debounce: 500
  });

  const loggedIn = localStorage.getItem('access_token') || null;
  
  return (
    <div className="wrapper">
      <ToastContainer />
      <Header />
      {
        loggedIn && (
          <>
            <SideBar />
          </>
        )
      }
      <Routes>
        <Route path="/" element={
          <AuthRoute>
          <Patient />
        </AuthRoute>
        } />
        <Route path="/dashboard" element={
          <AuthRoute>
            <Dashboard />
          </AuthRoute>
        } />
        <Route path="/customerlist" element={
          <AuthRoute>
            <Patient />
          </AuthRoute>
        } />
        <Route path="/customer/:id" element={
          <AuthRoute>
            
            <ShowPatientElement />
          </AuthRoute>
        } />
        <Route path="login" element={<Login />} />
        <Route path="reminder" element={
          <AuthRoute>
            <Reminder />
          </AuthRoute>
        } />
        <Route path="sms" element={
          <AuthRoute>
            <SmsLog />
          </AuthRoute>
        }/>
        <Route path="voice" element={
          <AuthRoute>
            <VoiceList />
          </AuthRoute>
        }/>
        <Route path="recharge" element={
          <AuthRoute>
            <Recharge />
          </AuthRoute>
        }/>
        <Route path="downloads" element={
          <AuthRoute>
            <Downloads />
          </AuthRoute>
        }/>
        <Route path="users" element={
          <AuthRoute>
            <UserList />
          </AuthRoute>
        }/>
        <Route path="users/add" element={
          <AuthRoute>
            <AddUser />
          </AuthRoute>
        }/>
        <Route path="users/edit/:id" element={
          <AuthRoute>
            <EditUser />
          </AuthRoute>
        }/>
      </Routes>
      <footer className="main-footer">
        <strong>Copyright &copy; 2014-2060 <a rel="noreferrer" href="https://gngengineering.co.za" target="_blank">GNG</a>.</strong>
         &nbsp;All rights reserved.
        <div className="float-right d-none d-sm-inline-block">
          <b>Version</b> {process.env.REACT_APP_VERSION}
        </div>
      </footer>
    </div>
  );
}

export default App;
