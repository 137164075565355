import React from 'react';
import { withTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { useParams } from 'react-router';
import PatientInformation from './partials/PatientInformation';

function ShowPatientElement (props) {
    const params = useParams();
    const id = params.id;

    return (
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ props.t('patient_info') }</h1>
                </div>
              </div>
            </div>
          </div>
          <PatientInformation id={id} />
        </div>
      </main>
    )
  }

export default (withTranslation() (ShowPatientElement));
