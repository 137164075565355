import http, { token } from "../../components/instances/http";
import { toast } from "react-toastify";

export const getPatients = (pageNumber = 1, pageSize = 10, centerName = '', 
hasPhone=null, hasFingerprint = null, isPDV = null, startDate='', searchText = '') => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/pagedPatients/?centerName='+centerName+
    '&pageNumber='+ pageNumber +
    '&hasPhonenumber='+ (hasPhone ?? '') +
    '&hasFingerprint='+ (hasFingerprint ?? '') +
    '&appointmentStartDate='+ (startDate ?? '') +
    '&isPDV='+ (isPDV ?? '') +
    '&generalSearch='+ (searchText ?? '') +
    '&pageSize='+pageSize, token())
      .then(response => {
        dispatch ({
          payload: response.data,
          type: "SET_PATIENTS"
        });
      })
      .catch(error => {
        if(error.status === 403){
          toast.error('You are not allowed to view this content');
        }
        else {
          toast.error('An error occurred! Could not fecth data at this time');
        }
      })
  }
}
export const getSynchData = () => {
  return async dispatch => {
    const response = await http.get(process.env.REACT_APP_MAINAPI + '/main/lastSynchPerCenter/', token());
    dispatch({
      payload: response.data,
      type: "SET_SYNCH"
    });
  }
};

export const getFingerprintData = () => {
  return async dispatch => {
    const response = await http.get(process.env.REACT_APP_MAINAPI + '/main/patientCountWithFingerprint/', token());
    dispatch({
      payload: response.data,
      type: "SET_FINGERPRINT_SUMMARY"
    });
  }
};

export const getPdvData = () => {
  return async dispatch => {
    const response = await http.get(process.env.REACT_APP_MAINAPI + '/main/patientCountByStatus/?status=Perdu de vue', token());
    dispatch({
      payload: response.data,
      type: "SET_PDV_SUMMARY"
    });
  }
};





export const downloadPatientList = (centerName) => {
  var config = token();
  config.responseType = 'blob';
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/downloadPatients?centerName='+centerName, config)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Export.csv'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        console.log(error);
        toast.error('An error occurred! Could not fecth data at this time');
      })
  }
}

export const getPatient = (id) => {
  
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/patients/' + id, token())
      .then(response => {
      dispatch ({
        payload: response.data,
        type: "SET_PATIENT"
      });
    })
  }
}

export const storeCustomer = (data) => {
  return async dispatch => {
    try {
      const response = await http.put(process.env.REACT_APP_MAINAPI + '/main/customers', data, token());
      if (response.data?.success) {
        toast.success("Success!");
      } else {
        toast.error("Error: " + response.data.errorMessage);
      }

      dispatch({
        type: "SAVE_CUSTOMER",
        payload: response.data
      });

    } catch (error) {
      console.log(error);
      dispatch({
        type: "SAVE_CUSTOMER",
        payload: error
      });
      toast.error("An error occurred!");
    }
  };
}
