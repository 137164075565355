import React from 'react';
import '../../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

function PatientSmsLog (props) {

  const columns = [
    {
      name: props.t('center_name'),
      selector: row => row.centerName,
      sortable: true,
      grow: 1
    },
    {
      name: props.t('message'),
      selector: row => row.message,
      sortable: true,
      grow: 2
    },
    {
      name: props.t('delivery_date'),
      selector: row => row.deliveryDate,
      sortable: true
    }
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={props.log}
        pagination
      />
    </>
  )
}

export default (withTranslation() (PatientSmsLog));
