import React from 'react';
import { getReminders } from '../../../store/actions/reminder';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import '../../../translations/i18n';
import SendReminder from './partials/SendReminder';

class Reminder extends React.Component {
  constructor (props) {
    super (props);
    this.state = {
      reminders: []
    }
  }

  async componentDidMount () {
    // await this.props.getReminders();
    // this.setState({
    //   reminders: this.props.reminders
    // });
  }

  handleSelectDate () {

  }


  render () {

    return (
      <>
        <main>
            <div className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col">
                    <div className="card">
                      
                      <div className="card-body reminder-block">
                          <SendReminder />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    reminders: state.reminderReducer.reminders
  }
};

export default connect (mapStateToProps, { getReminders }) (withTranslation() (Reminder));
