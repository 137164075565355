import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { storeCustomer } from '../../../../store/actions/patient';
import { getCenters } from '../../../../store/actions/center';
import { withTranslation } from 'react-i18next';
import validator from 'validator';
import Select from 'react-select';

const AddEndUser = ({ patient, t, storeCustomer, getCenters, centers }) => {
  const [state, setState] = useState({
    id: null,
    name: '',
    surname: '',
    password: '',
    phoneNumber: '',
    email: '',
    loading: false,
    errorName: '',
    errorSurname: '',
    errorEmail: '',
    errorPhoneNumber: '',
    errorPassword: '',
    active: false,
    centers: centers,
    submit: false,
    
  });

  useEffect(() => {
    getCenters(); // Assuming getCenters is an action to fetch centers
  }, [getCenters]);

  const init = () => {
    setState({
      id: null, name: '',
      surname: '',
      password: '',
      phoneNumber: '',
      email: '',
      loading: false,
      errorName: '',
      errorSurname: '',
      errorEmail: '',
      errorPhoneNumber: '',
      errorPassword: '',
      active: false,
      centers: centers,
      submit: false
    });
  };

  const loadUserData = (data) => {
    setState({
      name: data.name,
      surname: data.surname,
      phoneNumber: data.phoneNumber,
      email: data.email,
      active: data.active,
    });
  };

  const formatCentersForSelect = (centers) => {
    if (!centers) return;
    let formattedCenters = centers.map(center => ({
      label: center.name,
      value: center.id
    }));
    setState({
      centers: formattedCenters
    });

  };



  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    });
  };

  const handleActiveChange = (e) => {
    setState({
      ...state,
      active: e.target.checked
    });
  };

  const validateName = () => {
    setState({
      errorName: ''
    });
    if (state.name === '') {
      setState({
        errorName: t('error_name')
      });
      return false;
    }
    return true;
  };

  const validateSurname = () => {
    setState({
      errorSurname: ''
    });
    if (state.surname === '') {
      setState({
        errorSurname: t('error_surname')
      });
      return false;
    }
    return true;
  };

  const validateEmail = () => {
    setState({
      errorEmail: ''
    });
    if (!validator.isEmail(state.email)) {
      setState({
        errorEmail: t('error_email')
      });
      return false;
    }
    return true;
  };

  const validatePhoneNumber = () => {
    const phoneNumberRegex = /^\+?\d+$/;
    setState({
      errorPhoneNumber: ''
    });
    if (state.phoneNumber === '' || !phoneNumberRegex.test(state.phoneNumber)) {
      setState({
        errorPhoneNumber: t('error_phone_number')
      });
      return false;
    }
    return true;
  };

  const handleCenterChange = (option) => {
    let centerName = option ? option.value : '';
    setState({
      ...state,
      centerName: centerName
    });
  };

  const saveUser = async () => {
    let submit = validateName() && validateSurname() && validatePhoneNumber();
    if (submit) {
      setState({
        loading: true
      });

      var data = {
        name: state.name,
        surname: state.surname,
        email: state.email,
        centerName: state.centerName,
        phoneNumber: state.phoneNumber,
        active: state.active,
        id: state.id
      };

      await storeCustomer(data);
      
    }
  };


  useEffect(() => {
    if(patient?.success)
    init();
  }, [patient, init]);

  return (
    <main>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className='card-body'>
                      <form>
                      <div className="row">
                        <div className='col'><label>{t('active')}</label></div>
                        <div className='col'><input style={activeStyle} type="checkbox" checked={state.active? "checked": ""} name="active" className='form-control' onChange={handleActiveChange} /></div>
                      </div>
                      <div className="form-group">
                        <label>{t('name') }</label>
                        <input name="name" className='form-control' type="text" value={state.name} onChange={handleChange} disabled={state.loading} />
                        {
                          state.errorName !== '' && (
                            <span className="text-danger">{ state.errorName }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{t('surname') }</label>
                        <input name="surname" className='form-control' value={state.surname} type="text" onChange={handleChange} disabled={state.loading} />
                        {
                          state.errorSurname !== '' && (
                            <span className="text-danger">{ state.errorSurname }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{t('email') }</label>
                        <input name="email" className='form-control' value={state.email} type="text" onChange={handleChange} disabled={state.loading} autocomplete="off"/>
                        {
                          state.errorEmail !== '' && (
                            <span className="text-danger">{ state.errorEmail }</span>
                          )
                        }
                      </div>
                     
                      <div className="form-group">
                        <label>{t('phone_number') }</label>
                        <input name="phoneNumber" className='form-control' value={state.phoneNumber} type="text" onChange={handleChange} disabled={state.loading} />
                        {
                          state.errorPhoneNumber !== '' && (
                            <span className="text-danger">{ state.errorPhoneNumber }</span>
                          )
                        }
                      </div>
                    
                      <div className='form-group'>
                      <label>{t('select_center')}</label>
                                <Select
                                  name="centerName"
                                  options={state.centers}
                                  onChange={handleCenterChange}
                                  isClearable={true}
                                />
                      </div>
                      <button type='button' className='btn btn-primary pull-right' onClick={saveUser} disabled={state.loading}>
                        {state.loading === true ? t('loading'): t('save')}
                      </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </main>
  );
};

const activeStyle = {
  float: 'right',
  margin: '-5px',
  width: '25px'
};

const mapStateToProps = (state) => {
  return {
    patient: state.patientReducer.patient
  };
}

export default connect(mapStateToProps, {storeCustomer, getCenters})(withTranslation()(AddEndUser));
