import reminders from '../../data/stubs/reminder.json';

export const getReminders = () => {
  return dispatch => {
    dispatch ({
      type: "SET_REMINDERS",
      payload: reminders
    });
  }
}

export const getReminder = (id) => {
  let reminder = reminders.filter(reminder => {
    return reminder.id === id ;
  });

  return dispatch => {
    dispatch ({
      type: "SET_REMINDER",
      payload: reminder[0]
    });
  }
}
