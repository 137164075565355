export const TRANSLATIONS_EN = {
  "dashboard": "Dashboard",
  "dashboard_synch":"Center Synchronization Summary",
  "dashboard_pdvData_summary": "PDV Summary",
  "french": "French",
  "english": "English",
  "enduser_list": "Customer list",
  "synch_date":"Import Date",
  "import_station":"Import Station",
  "patients": "Patients",
  "name": "Name",
  "sendCall": "Call All Customer Selected",
  "surname": "Surname",
  "file_number": "File number",
  "next_appointment": "Next appointment",
  "patient_info": "Customer information",
  "date_of_birth": "Date of birth",
  "health_status": "Health status",
  "last_visit": "Last visit",
  "gender": "Gender",
  "hiv_outcome": "HIV outcome",
  "resultat": "Resultat",
  "art_start_date": "ART Start date",
  "phone_number": "Phone number",
  "alternate_number" : "Alternative Number",
  "role": "Role",
  "province": "Province",
  "center_name": "Center name",
  "has_fingerprint": "Has finger print",
  "address": "Address",
  "visits": "Visits",
  "date": "Date",
  "regimen_code": "Regimen code",
  "notes": "Notes",
  "search": "Search",
  "number_of_patients":"Number of patients",
  "select_district": "Select district",
  "select_center": "Select center",
  "patient": "Patient",
  "reminder": "Reminder",
  "reminders": "Reminders",
  "channel": "Channel",
  "status": "Status",
  "message": "Message",
  "center": "Center",
  "voice_log": "Voice log",
  "sms_log": "SMS log",
  "user_managment": "User Management",
  "download_apps": "Download Apps",
  "recharge": "Recharge",
  "otp_header": "Please enter the OTP code sent to your phone",
  "otp": "OTP",
  "verify_otp": "Confirm OTP",
  "amount_in_usd": "Please enter the amount in ZAR",
  "send_reminder": "Send",
  "select_patient": "Select a patient",
  "select_reminder_date": "Select reminder date",
  "upload_audio": "Upload an audio",
  "enter_message": "Enter message",
  "sign_in": "Sign in",
  "email": "Email",
  "password": "Password",
  "login_error": "Email and/or password invalid",
  "delivery_date": "Delivery date",
  "wait_for_patients_to_load": "Please wait while we loading patients",
  "select_a_correct_patient": "Please select a patient to send a reminder",
  "select_correct_channel": "Please select a correct channel: SMS or Appel",
  "reminder_sent": "Reminder sent!",
  "reminder_not_sent": "Reminder could not be sent at the moment",
  "download": "Download",
  "dashboard_fingerprint_summary": "Number of Patients with Fingerprint by Center",
  "selection": "Selection",
  "ivr_selection": "IVR selection",
  "success": "Success",
  "sms_remaining": "SMS remaining",
  "voice_remaining": "Voice calls remaining",
  "users": "Users",
  "last_login": "Last login",
  "active": "Active",
  "add_user": "Add user",
  "add_enduser": "Add End User",
  "save": "Save",
  "password_confirmation": "Password confirmation",
  "error_name": "The name field is required",
  "error_surname": "The surname field is required",
  "error_phone_number": "The phone number field is required",
  "error_email": "The email field is required",
  "error_password": "The password field is required (Min 8, Min 1 Majuscule, Min 1 Miniscule, Min 1 character special, Min 1 Number)",
  "password_mismatch": "Password mismatch",
  "saved": "Saved",
  "filter_patient_by": "Filter customer by",
  "filter_lost_sight": "Lost sight",
  "filter_with_phone": "With phone",
  "filter_without_phone": "Without phone",
  "filter_appointment": "With Appointment",
  "filter_fingerprint": "With Fingerprints",
  "filter_nofingerprint": "No Fingerprints"
};