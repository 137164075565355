import React, { useState } from 'react';
import { Image } from 'cloudinary-react';
import axios from 'axios';
import { getTime } from 'date-fns';
import VoiceRecording from './VoiceRecording';

const CloudinaryUploader = () => {
  var [selectedFile, setSelectedFile] = useState(null);
  const [uploadResult, setUploadResult] = useState(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingUpload, setLoadingUpload] = useState(false);
  const defaultUploadMessage = 'Choose an MP3 or WAV File';

  const handleFileChange = async (e) => {
    const file = e.target.files[0];

    if (file && (file.type === 'audio/mpeg' || file.type === 'audio/mp3' || file.type === 'audio/wav')) {
      setLoadingFile(true);
      setSelectedFile(file);
      setLoadingFile(false);
    } else {
      alert('Invalid file type. Please select an MP3 or WAV file.');
      setLoadingFile(false);
    }
  };

  const handleUpload = async () => {
    try {
      if (!selectedFile) {
        alert('No file selected');
        return;
      }

      setLoadingUpload(true);

      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('api_key', '462494933514153');
      formData.append('resource_type', 'video');
      formData.append('timestamp', Date.now);
      //formData.append('signature', 'SJzTiFhELfaYCoZfAWZTMyVm1mE');
      formData.append('upload_preset', 'c0vb0baq');

      const response = await axios.post(
        //CLOUDINARY_URL=cloudinary://462494933514153:SJzTiFhELfaYCoZfAWZTMyVm1mE@gng-engineering
        'https://api.cloudinary.com/v1_1/gng-engineering/video/upload',
        formData
      );

      setUploadResult(response.data);
      selectedFile = this.useState(null);
    } catch (error) {
      console.error('Error uploading file:', error);
    } finally {
      setLoadingUpload(false);
    }
  };

  return (
    <div>
      {/* <VoiceRecording></VoiceRecording> */}
      <input
        type="file"
        accept=".mp3, .wav"
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="fileInput"
      />
      <div style={rowStyle}>
        <label htmlFor="fileInput" style={fileInputLabelStyle} aria-label="Choose an MP3 or WAV File">
          <i className="fas fa-file-audio" style={iconStyle}></i>
          {loadingFile ? 'Loading...' : selectedFile ? `Selected file: ${selectedFile.name}` : defaultUploadMessage}
        </label>
        {(selectedFile != null || selectedFile !== defaultUploadMessage) && (
        <button onClick={handleUpload} style={uploadButtonStyle} disabled={loadingFile || loadingUpload}>
          {loadingUpload ? (
            <>
              <i className="fas fa-spinner fa-spin" style={{ marginRight: '8px' }}></i>
              Uploading...
            </>
          ) : (
            <>
              <i className="fas fa-cloud-upload-alt" style={{ marginRight: '8px' }}></i>
              Upload
            </>
          )}
        </button>
        )}
      </div>

      {uploadResult && (
        <div>
          <h2 style={resultHeaderStyle}>Upload Result</h2>
          {/* <Audio cloudName="your_cloudinary_cloud_name" publicId={uploadResult.public_id} /> */}
        </div>
      )}
    </div>
  );
};

const containerStyle = {
  maxWidth: '400px',
  margin: 'auto',
  textAlign: 'center',
};

const rowStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
};

const fileInputLabelStyle = {
  display: 'flex',
  alignItems: 'center',
  padding: '15px',
  marginRight:'5px',
  marginBottom: '0px',
  cursor: 'pointer',
  backgroundColor: '#007bff',
  color: 'white',
  borderRadius: '5px',
};

const iconStyle = {
  marginRight: '10px',
};

const uploadButtonStyle = {
  padding: '15px',
  cursor: 'pointer',
  backgroundColor: '#28a745',
  color: 'white',
  border: 'none',
  borderRadius: '5px',
  display: 'flex',  // Ensure the button takes the same height as the other button
  alignItems: 'center', // Align content vertically in the center
};

const resultHeaderStyle = {
  marginTop: '20px',
  fontSize: '1.5em',
  color: '#333',
};

export default CloudinaryUploader;
