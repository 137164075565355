const initialState = {
  reminders: [],
  reminder: null
};

export default function reminder (state = initialState, action) {
  switch (action.type) {
    case "SET_REMINDERS":
      return {
        ...state,
        reminders: action.payload
      }
    case "SET_REMINDER":
      return {
        ...state,
        reminder: action.payload
      }
    default:
      return state;
  }
}
