import React from 'react';
import '../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { download } from '../../../store/actions/download';
import { Spinner } from 'react-bootstrap';

class Downloads extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
          loading: false,
        }
      }

    async download(filename){
        this.setState({
            loading: true
          });
        await this.props.download(filename);

        this.setState({
            loading: false
          });
    }
    render () {
        return (
            <main>
                <div className="content-wrapper">
                    <div className="content">
                        <div className="container-fluid">
                            
                            <div className="card">
                                <div className="card-header">
                                    Applications

                                    {this.state.loading && (
                                    <div className="text-center">
                                        <Spinner animation='grow' />
                                    </div>
                                    )}
                                </div>
                                <div className="card-body">
                                    <ul>
                                        <li><button onClick={() => {this.download('synch_app.zip')}}>Synch App</button></li>
                                        <li><button onClick={() => {this.download('afyacloud.apk')}}>AfyaCloud Mobile App</button></li>
                                    </ul>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </main>
        )
    };
}
const mapStateToProps = (state) => {
    
}
export default connect (mapStateToProps, {download}) (withTranslation() (Downloads));