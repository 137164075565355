import React from 'react';
import { getPatients, downloadPatientList } from '../../../store/actions/patient';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import { withTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { Spinner } from 'react-bootstrap';
import { getCenters } from '../../../store/actions/center';
import Select from 'react-select';
import ReactExport from "react-data-export";
import AddEndUser from './partials/AddEndUser';
import CloudinaryUploader from './partials/CloudinaryUploader';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

class Patient extends React.Component {
  childComponentRef = React.createRef();
  constructor (props) {
    super (props);
    
    this.handleFilterPatients = this.handleFilterPatients.bind(this);

    this.state = {
      patients: [],
      loading: false,
      centers: [],
      selectedCenter: '',
      searchFilter: '',
      hasFingerPrint: '',
      withPhone: null,
      isPDV: '',
      totalRows: 0,
      page : 1,
      pageSize: 10,
    }
    
  }
  
  async componentDidMount () {
    
    this.setState({
      loading: true
    });
    //Do not load all patients on startup.
    //await this.props.getPatients();
    await this.props.getCenters();
    this.formatCentersForSelect(this.props.centers);
    this.setState({
      patients: this.props.patients,
      totalRows: this.props.totalRows,
      role: this.props.role,
      loading: false
    });
  }
  
  async download ()
  {

    this.setState({
      loading: true
    });

    await this.props.downloadPatientList(this.state.selectedCenter);

    this.setState({
      loading: false
    });
  
  }

  onSearch = async(e) => {
    let searchText = e.target.value;

    this.setState({
      searchText : searchText,
      patients: this.props.patients,
    });

    if( searchText.length < 2)
      return;

    let filteredItems = [];
    this.setState({
      loading: true,
      patients: this.props.patients,
      hasFingerPrint : null,
      isPDV : '',
      withPhone : '',
      startDate : '',
      searchText : searchText
    });
  
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      this.state.withPhone,
      this.state.hasFingerPrint,
      this.state.isPDV,
      this.state.startDate,
      searchText);
      this.setState({
        page: this.state.page,
        totalRows: this.props.totalRows,
        patients: this.props.patients,
        loading: false
      });
  }

  handleCenterChange = async (option) => {
    let searchCenter = option ? option.value : '';
    
    this.setState({
      selectedCenter: searchCenter,
      loading: true,
    });

    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      searchCenter,
      this.state.withPhone,
      this.state.hasFingerPrint,
      this.state.isPDV,
      this.state.startDate,
        this.state.searchText);

      this.setState({
        totalRows: this.props.totalRows,
        patients: this.props.patients,
        loading: false
      });
    
    // let filteredItems = this.props.patients.filter (patient => {
    //   let row = patient.centerName.toLowerCase();
    //   return row.indexOf(searchCenter.toLowerCase()) !== -1;
    // });
    // this.setState({
    //   patients: filteredItems
    // });
  }

  formatCentersForSelect (centers) {
    let formattedCenters = centers.map(center => {
      return {
        label: center.name,
        value: center.name
      }
    });
    this.setState({
      centers: formattedCenters
    });
  }

  handleFilterPatients = async(option) => {
    let searchFilter = option ? option.value : '';
    if (searchFilter === 'lost_sight') {
      this.filterLostSight();
    } else if (searchFilter === 'with_phone') {
      this.filterWithPhone();
    } else if (searchFilter === 'without_phone') {
      this.filterWithoutPhone();
    } else if (searchFilter === 'appointment') {
      this.filterAppointment();
    }
    else if (searchFilter === 'fingerprint') {
      this.filterWithFingerprint();
    }
    else if (searchFilter === 'nofingerprint') {
      this.filterNoFingerprint();
    }
    else {

      this.setState({
        loading: true,
        patients: this.props.patients,
        hasFingerPrint : null,
        isPDV : '',
        withPhone : '',
        startDate : '',
        searchText : '',
      });
    
      await this.props.getPatients(
        this.state.page,
        this.state.pageSize,
        this.state.selectedCenter,
        this.state.withPhone,
        this.state.hasFingerPrint,
        this.state.isPDV);

        this.setState({
          loading: false,
          patients: this.props.patients,
          searchFilter: searchFilter,
          totalRows: this.props.totalRows,
        });
    }
    
    
    
  }

  async filterLostSight () {
    this.setState({
      loading: true,
      hasFingerPrint : null,
      isPDV : true,
      withPhone : '',
      startDate : '',
      searchText : '',
      lastFilter : {name: 'lostSight', value: true}
    });
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      '',
      '',
      true,
      '');
    this.setState({
      page: this.state.page,
      totalRows: this.props.totalRows,
      patients: this.props.patients,
      loading: false
    });
  }

  async filterWithPhone () {
    this.setState({
      loading: true,
      hasFingerPrint : null,
      isPDV : '',
      withPhone : true,
      searchText : '',
      startDate : '',
    });
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      true,
      '',
      '',
      '');
    this.setState({
      page: this.state.page,
      totalRows: this.props.totalRows,
      patients: this.props.patients,
      loading: false
    });
  }

  async filterWithoutPhone () {
    this.setState({
      loading: true,
      hasFingerPrint : null,
      isPDV : '',
      withPhone : false,
      searchText : '',
      startDate : '',
    });
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      false,
      '',
      '',
      '');
    this.setState({
      page: this.state.page,
      totalRows: this.props.totalRows,
      patients: this.props.patients,
      loading: false
    });
  }

  async filterWithFingerprint() {
    this.setState({
      loading: true,
      hasFingerPrint : true,
      isPDV : '',
      searchText : '',
      withPhone : '',
      startDate : '',
    });
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      '',
      true,
      '');
    this.setState({
      page: this.state.page,
      totalRows: this.props.totalRows,
      patients: this.props.patients,
      loading: false
    });
  }

  async filterNoFingerprint() {
    this.setState({
      loading: true,
      hasFingerPrint : false,
      isPDV : '',
      withPhone : '',
      searchText : '',
      startDate : '',
    });
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      '',
      false,
      '');
    this.setState({
      page: this.state.page,
      totalRows: this.props.totalRows,
      patients: this.props.patients,
      loading: false
    });
  }

  async editUser(data){
    if(this.childComponentRef.current)
      this.childComponentRef.current.loadUserData(data);
  }

  

  async filterAppointment () {
    this.setState({
      loading: true,
      hasFingerPrint : null,
      isPDV : '',
      searchText : '',
      withPhone : '',
      startDate : '1987-03-28',
    });
    await this.props.getPatients(
      this.state.page,
      this.state.pageSize,
      this.state.selectedCenter,
      '',
      '',
      '',
      '1987-03-28');
    this.setState({
      page: this.state.page,
      totalRows: this.props.totalRows,
      patients: this.props.patients,
      loading: false
    });
  }


  render () {
    const columns = [
      {
        name: 'Actions',
        cell: row => <>
        <a href={'/customer/' + row.id} className="btn btn-primary btn-sm">
          <i className="fa fa-eye"> </i>
          </a>
          <button className='btn btn-secondary' style={{marginLeft: '5px'}} onClick={this.editUser(row)}><i className="fa fa-user"> </i></button>
          </>
      },
      {
        name: this.props.t('name'),
        selector: row => row.name,
        sortable: true
      },
      {
        name: this.props.t('surname'),
        selector: row => row.surname,
        sortable: true
      },
      {
        name: this.props.t('phone_number'),
        selector: row => row.phoneNumber,
        grow: 2,
        sortable: true
      },
      {
        name: this.props.t('center_name'),
        selector: row => row.centerName,
        sortable: true
      },
      {
        name: this.props.t('active'),
        selector: row => row.active,
        sortable: true
      },
      {
        name: this.props.t('synch_date'),
        selector: row => row.importedDate,
        sortable: true,
        grow: 2
      }
      
    ];

    
    const  handlePageChange = async (page) => {
      this.setState({
        loading: true,
      });
      await this.props.getPatients(page,
        this.state.pageSize,
        this.state.selectedCenter,
        this.state.withPhone,
        this.state.hasFingerPrint,
        this.state.isPDV,
        this.state.startDate,
        this.state.searchText);
      
        this.setState({
        page: page,
        totalRows: this.props.totalRows,
        patients: this.props.patients,
        loading: false
      });
    }
    
    const handlePerRowsChange = async (newPageSize, page) => {
      this.setState({
        loading: true,
      });
      await this.props.getPatients(page, newPageSize, 
        this.state.selectedCenter, 
        this.state.withPhone,
        this.state.hasFingerPrint,
        this.state.isPDV,
        this.state.startDate,
        this.state.searchText);
      this.setState({
        page: page,
        totalRows: this.props.totalRows,
        patients: this.props.patients,
        loading: false,
        pageSize: newPageSize
      });
    }

    const filters = [
      {
        label: 'All Customers',
        value: ''
      }
    ];

    return (
      
      <>
        <main>
          <div className="content-wrapper">
            <div className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1 className="m-0">{ this.props.t('enduser_list') }</h1>
                  </div>
                </div>
              </div>
            </div>
            <div className="content">
                  
              <div className="container-fluid">
                <div className="row">
                  
                  <CloudinaryUploader/>
                  
                </div>
                <div className="row">
                  <div className='col-md-3'>
                    {this.state.centers.length > 0 && (
                    <AddEndUser ref={this.childComponentRef} centers={this.state.centers} action={this.handleFilterPatients}/>
                    )}
                  </div>
                  <div className="col-md-8">
                    <div className="card">
                      <div className="card-header">
                        <div className="pull-right">
                          <input type="text" name="search" className="form-control" placeholder={this.props.t('search')} onChange={this.onSearch} />
                        </div>
                      </div>
                      {
                        this.state.centers.length > 0 && (

                          <div className="card-header">
                            <div className="form-inline">
                              <div className="form-group col-md-6">
                                <label className='col-md-12'>{this.props.t('select_center')}</label>
                                <Select
                                  options={this.state.centers}
                                  onChange={this.handleCenterChange}
                                  isClearable={true}
                                  className='col-md-12'
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label className='col-md-12'>{this.props.t('filter_patient_by')}</label>
                                <Select
                                  options={filters}
                                  onChange={this.handleFilterPatients}
                                  
                                  isClearable={true}
                                  className='col-md-12'
                                />
                              </div>
                            </div>
                          </div>
                        )
                      }
                      <div className="card-body">
                        {
                          this.state.loading && (
                            <div className="text-center">
                              <p>Please wait while we fetching the data</p>
                              <Spinner animation='grow' />
                            </div>
                          )
                        }
                        {
                          ! this.state.loading && (this.state.role === 'Admin' ||this.state.role === 'SuperAdmin')  &&  (
                            <button className="btn btn-primary btn-small" onClick={() => {this.download()}}>
                              {this.props.t('download')}
                            </button>
                          )

                         
                        }
                         <button className="btn btn-primary btn-small pull-right success" onClick={() => {this.sendVoiceCall()}}>
                              {this.props.t('sendCall')}
                            </button>
                        {
                          this.state.patients.length > 0 && (
                            <DataTable
                              columns={columns}
                              data={this.state.patients}
                              pagination
                              paginationServer
                              paginationRowsPerPageOptions={[10, 25, 50, 100,499]}
                              paginationTotalRows={this.state.totalRows}
                              onChangePage={handlePageChange}
                              onChangeRowsPerPage={handlePerRowsChange}
                            />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patientReducer.patients,
    totalRows : state.patientReducer.totalRows,
    centers: state.centerReducer.centers,
    role: state.authReducer.role
  }
};

export default connect (mapStateToProps, { getPatients, getCenters, downloadPatientList }) (withTranslation() (Patient));
