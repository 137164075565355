const initialState = {
  smses: [],
  sent: false,
  balance: 0
}

export default function sms (state = initialState, action) {
  switch (action.type) {
    case "SET_SMSES":
      return {
        ...state,
        smses: action.payload
      };
    case "SET_SMS_SENT":
      return {
        ...state,
        sent: action.payload
      };
    case "SET_SMS_BALANCE":
      return {
        ...state,
        balance: action.payload ?? 0
      };
    default:
      return state; 
  }
}
