import http, {token} from "../../components/instances/http";

export const getSmses = () => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/sms/', token())
      .then(response => {
        dispatch({
          type: "SET_SMSES",
          payload: response.data
        })
      })
      .catch(error => {
        console.log(error.data);
      });
  }
}
export const updateSmsBalance = (balance) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_MAINAPI+'/main/smsrecharge', balance, token())
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "SET_SMSBALANCE_UPDATED",
            payload: true
          });
        } else {
          dispatch({
            type: "SET_SMSBALANCE_UPDATED",
            payload: false
          });
        }
      })
      .catch (error => {
        console.log(error);
        dispatch({
          type: "SET_SMSBALANCE_UPDATED",
          payload: false
        });
      });
  };
}
export const sendSms = (data) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_SMSAPI+'/sms/send', data, token())
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "SET_SMS_SENT",
            payload: true
          });
        } else {
          dispatch({
            type: "SET_SMS_SENT",
            payload: false
          });
        }
      })
      .catch (error => {
        console.log(error);
        dispatch({
          type: "SET_SMS_SENT",
          payload: false
        });
      });
  }
}

export const getBalance = () => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/balance', token())
      .then(response => {
        console.log(response.data);
        dispatch({
          type: "SET_SMS_BALANCE",
          payload: response.data.sms
        });
      })
      .catch(error => {
        console.log(error);
      })
  };
};
