import React from 'react';
import '../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { getUsers } from '../../../store/actions/user';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';

class UserList extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      users: [],
      loading: false
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await this.props.getUsers();
    this.setState({
      users: this.props.users,
      loading: false
    });
  }

  searchUser = (e) => {
    let filteredItems = this.props.users.filter(user => {
      let row = user.name.toLowerCase() + user.surname.toLowerCase() + user.email.toLowerCase() + user.phoneNumber;
      return row.indexOf(e.target.value.toLowerCase()) !== -1; 
    });

    this.setState({
      users: filteredItems
    });
  }

  render () {
    const columns = [
      {
        name: this.props.t('name'),
        selector: 'name',
        sortable: true
      },
      {
        name: this.props.t('surname'),
        selector: 'surname',
        sortable: true
      },
      {
        name: this.props.t('email'),
        selector: 'email',
        sortable: true,
        grow: 2
      },
      {
        name: this.props.t('phone_number'),
        selector: 'phoneNumber',
        sortable: true
      },
      {
        name: this.props.t('last_login'),
        selector: 'lastLoginDate',
        sortable: true
      },
      {
        name: this.props.t('active'),
        selector: 'active',
        cell: row => row.active ?
                      <><span className='text-success'><i className='fa fa-check'></i> </span></>
                      : <><span className='text-danger'><i className='fa fa-times'></i> </span></>,
        sortable: true
      },
      {
        name: '',
        cell: row =>  <>
                          <Link to={'/users/edit/' + row.id} state={{ user: row }}>
                            <i className='fa fa-edit'></i>
                          </Link>
                      </>
      }
    ];

    return (
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ this.props.t('users') }</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      <div className='pull-right'>
                        <input type='text' name='search' placeholder={ this.props.t('search') } className='form-control' onChange={this.searchUser} />
                      </div>
                    </div>
                    <div className="card-body">
                      <p>
                        <a href='/users/add' className='btn btn-primary'><i className='fa fa-plus'></i> </a>
                      </p>
                      {
                        this.state.loading && (
                          <>
                            <Spinner animation='grow' />
                          </>
                        )
                      }
                      <DataTable
                        columns={columns}
                        data={this.state.users}
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.userReducer.users
  }
};

export default connect (mapStateToProps, { getUsers }) (withTranslation() (UserList));
