import React from 'react';
import '../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { getSmses, getBalance } from '../../../store/actions/sms';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import csvDownload from 'json-to-csv-export'
import { Spinner } from 'react-bootstrap';
class SmsLog extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      smses: [],
      search: '',
      loading: false,
      balance: 0
    }
  }

  async componentDidMount () {
    this.setState({
      loading: true
    });
    await this.props.getSmses();
    await this.props.getBalance();
    this.setState({
      smses: this.props.smses.sort(function(a,b){
        return new Date(b.deliveryDate) - new Date(a.deliveryDate);
      }),
      balance: this.props.balance,
      loading : false
    });
  }
  download (){
    csvDownload(this.props.smses);
  }

  onSearchChange = (e) => {
    let search = e.target.value;
    let filteredSms = [];
    this.setState({
      loading: true
    });
    var delimiter = " ";
    filteredSms = this.props.smses.filter(sms => {
      let row = sms.centerName.toLowerCase() + delimiter +
      sms.name.toLowerCase() + delimiter +
      sms.surname.toLowerCase() + delimiter +
      sms.deliveryDate.toLowerCase() + delimiter +
      sms.phoneNumber.toLowerCase();
      return row.indexOf(search.toLowerCase()) !== -1;
    });
    
    this.setState({
      smses: filteredSms,
      loading: false
    });
  }

  render () {
    const columns = [
      {
        name: this.props.t('name'),
        selector: row => row.name,
        sortable: true
      },
      {
        name: this.props.t('surname'),
        selector: row => row.surname,
        sortable: true
      },
      {
        name: this.props.t('center_name'),
        selector: row => row.centerName,
        sortable: true
      },
      {
        name: this.props.t('phone_number'),
        selector: row => row.phoneNumber,
        sortable: true
      },
      {
        name: this.props.t('message'),
        selector: row => row.message,
        sortable: true,
        grow: 4
      },
      {
        name: this.props.t('delivery_date'),
        selector: row => row.deliveryDate,
        sortable: true
      },
      {
        name: this.props.t('success'),
        selector: row => row.success,
        cell: row => <>
                      <div className='status'>
                        <span className={row.success === 'true' ? 'text-success' : 'text-danger'}>
                          <i className={row.success ==='true' ? 'fa fa-check' : 'fa fa-times'}> </i>
                        </span>
                      </div>
                     </>,
        sortable: true
      }
    ];

    const smses = this.state.smses;

    return (
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ this.props.t('sms_log') }</h1>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3>{ this.state.balance + ' ' + this.props.t('sms_remaining') }</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      <div className="pull-right">
                        <input type="text" className="form-control" name="search" placeholder={this.props.t('search')} onChange={this.onSearchChange} />
                      </div>
                      {this.props.t('sms_log')}
                    </div>
                    <div className="card-body">
                    {
                this.state.loading && (
                  <div className="text-center">
                    <Spinner animation='grow' />
                  </div>
                )
              }
                      {
                        this.state.loading === false && (
                          <>
                            <button className="btn btn-primary btn-small" onClick={() => {this.download()}}>
                              {this.props.t('download')}
                            </button>
                            <DataTable
                              columns={columns}
                              data={smses}
                              pagination
                            />
                          </>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    smses: state.smsReducer.smses,
    balance: state.smsReducer.balance
  };
};

export default connect(mapStateToProps, { getSmses, getBalance }) ((withTranslation()) (SmsLog));
