const initialState = {
  loggedIn: localStorage.getItem('access_token') ? true : false,
  token: null,
  role: localStorage.getItem('role') || '',
  otp: false
};

export default function auth (state = initialState, action) {
  switch (action.type) {
    case "SET_OTP_REQUEST":
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        phoneNumber: action.payload.phoneNumber,
        otp: action.payload.otp,
        otpNumber: action.payload.otpNumber,
        userId: action.payload.userId,
      };
      case "SET_LOGGED_IN":
      return {
        ...state,
        loggedIn: action.payload.loggedIn,
        token: action.payload.token,
        role: action.payload.role
      };
    case "SET_LOGGED_OUT":
      localStorage.clear();
      return {
        ...state,
        loggedIn: false,
        otp: false,
      }
    default:
      return state;
  }
}