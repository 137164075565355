import { combineReducers } from "redux";
import patient from './patient';
import reminder from './reminder';
import auth from './auth';
import sms from './sms';
import voice from './voice';
import center from "./center";
import user from "./user";

const rootReducer = combineReducers({
  authReducer: auth,
  patientReducer: patient,
  reminderReducer: reminder,
  smsReducer: sms,
  voiceReducer: voice,
  centerReducer: center,
  userReducer: user
});

export default rootReducer;
