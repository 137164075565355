import React from 'react';
import '../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { getVoices, getBalance } from '../../../store/actions/voice';
import { connect } from 'react-redux';
import DataTable from 'react-data-table-component';
import csvDownload from 'json-to-csv-export';
import { Spinner } from 'react-bootstrap';

class VoiceList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      voices: [],
      balance: 0
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true
    });
    await this.props.getVoices();
    await this.props.getBalance();
    this.setState({
      voices: this.props.voices.sort(function(a,b){
        return new Date(b.callDate) - new Date(a.callDate);
      }),
      balance: this.props.balance,
      loading : false
    });
  }

  download (){
    csvDownload(this.props.voices);
  }

  onSearchChange = (e) => {
    let search = e.target.value;
    let filtered = [];
    this.setState({
      loading: true
    });
    var delimiter = " ";
    filtered = this.props.voices.filter(voice => {
      let row = voice.centerName.toLowerCase() + delimiter +
      voice.name.toLowerCase() + delimiter +
      voice.surname.toLowerCase() + delimiter +
      voice.callDate.toLowerCase() + delimiter +
      voice.ivrSelection.toLowerCase() + delimiter +
      voice.phoneNumber.toLowerCase();
      return row.indexOf(search.toLowerCase()) !== -1;
    });
    
    this.setState({
      voices: filtered,
      loading: false
    });
  }
  render () {
    const columns = [
      {
        name: this.props.t('phone_number'),
        selector: row => row.phoneNumber,
        sortable: true
      },
      {
        name: this.props.t('name'),
        selector: row => row.name,
        sortable: true
      },
      {
        name: this.props.t('surname'),
        selector: row => row.surname,
        sortable: true
      },
      {
        name: this.props.t('center_name'),
        selector: row => row.centerName,
        sortable: true,
      },
      {
        name: this.props.t('selection'),
        selector: row => row.selection,
        sortable: true
      },
      {
        name: this.props.t('ivr_selection'),
        selector: row => row.ivrSelection,
        sortable: true,
      },
      
      {
        name: this.props.t('success'),
        selector: row => row.success,
        cell: row => <>
                      <div className='status'>
                        <span className={row.success ? 'text-success' : 'text-danger'}>
                          <i className={row.success ? 'fa fa-check' : 'fa fa-times'}> </i>
                        </span>
                      </div>
                     </>,
        sortable: true
      },
      {
        name: this.props.t('date'),
        selector: row => row.callDate,
        sortable: true
      }
    ];

    return (
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ this.props.t('voice_log') }</h1>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h3>{ this.state.balance + ' ' + this.props.t('voice_remaining') }</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      <div className="pull-right">
                        <input type="text" className="form-control" name="search" placeholder={this.props.t('search')} onChange={this.onSearchChange} />
                      </div>
                      {this.props.t('voice_log')}
                    </div>
                    <div className="card-body voice-list-body">
                    {
                this.state.loading && (
                  <div className="text-center">
                    <Spinner animation='grow' />
                  </div>
                )
              }
                      <button className="btn btn-primary btn-small" onClick={() => {this.download()}}>
                        {this.props.t('download')}
                      </button>
                      <DataTable
                        columns={columns}
                        data={this.state.voices}
                        pagination
                        defaultSortAsc={false}
                        defaultSortFieldId = {'callDate'}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    voices: state.voiceReducer.voices,
    balance: state.voiceReducer.balance
  }
};

export default connect (mapStateToProps, { getVoices, getBalance }) ((withTranslation()) (VoiceList));
