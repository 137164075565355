export const TRANSLATIONS_FR = {
  "dashboard": "Tableau de bord",
  "dashboard_synch":"Synchronization des centres",
  "french": "Francais",
  "english": "Anglais",
  "enduser_list": "Liste des clients",
  "patients": "Clients",
  "synch_date":"Date de synchronization",
  "dashboard_fingerprint_summary": "Patients avec emprunte digitale",
  "dashboard_pdvData_summary": "Perdu de vue",
  "import_station":"Identifiant du PC",
  "name": "Prénom",
  "surname": "Nom",
  "sendCall": "Lancer un appel a tout les clients",
  "number_of_patients":"Nombre de patients",
  "file_number": "Numero de fiche",
  "next_appointment": "Prochain rendez-vous",
  "patient_info": "Informations du client",
  "date_of_birth": "Date de naissance",
  "health_status": "Etat de sante",
  "last_visit": "Derniere visite",
  "gender": "Sexe",
  "hiv_outcome": "Resultat VIH",
  "resultat": "Resultat",
  "art_start_date": "ART date de commencement",
  "phone_number": "Numero de telephone",
  "alternate_number" : "Numero alternatif",
  "role": "Role",
  "province": "Province",
  "center_name": "Nom du centre",
  "has_fingerprint": "Emprunte digitale",
  "address": "Addresse",
  "visits": "Visites",
  "date": "Date",
  "regimen_code": "Régime thérapeutique",
  "notes": "Notes",
  "search": "Rechercher",
  "select_district": "Sélectionner le district",
  "select_center": "Sélectionner le centre",
  "patient": "Patient",
  "reminder": "Rappel",
  "reminders": "Rappels",
  "channel": "Canal",
  "status": "Status",
  "message": "Message",
  "center": "Centre",
  "voice_log": "Liste des appels",
  "sms_log": "Liste des SMS",
  "user_managment": "Utilisateurs",
  "download_apps": "Telechargements",
  "recharge": "Recharge",
  "otp_header": "Veuillez capturer le code OTP envoyé à votre telephone portable",
  "otp": "OTP",
  "verify_otp": "Verifier OTP",
  "amount_in_usd": "Veuillez entrer le montant en Rands",
  "send_reminder": "Envoyer",
  "select_patient": "Sélectionner un patient",
  "select_reminder_date": "Sélectionner la date de rendez-vous",
  "upload_audio": "Télécharger un audio",
  "enter_message": "Saisir le message",
  "sign_in": "S'identifier",
  "email": "Addresse e-mail",
  "password": "Mot de passe",
  "login_error": "Email ou mot de passe incorrecte",
  "delivery_date": "Date de réception",
  "wait_for_patients_to_load": "Veuillez patienter, nous telechargeons les patients",
  "select_a_correct_patient": "Sélectionnez un patient afin de pouvoir envoyer un rappel",
  "select_correct_channel": "Choisissez un moyen de transmission: SMS ou Appel",
  "reminder_sent": "Rappel envoyé!",
  "reminder_not_sent": "Envoi du rappel a echoué. Une erreur de serveur...",
  "download": "Télécharger",
  "selection": "Option séléctionnée",
  "ivr_selection": "Séléction IVR",
  "success": "Résultat",
  "sms_remaining": "SMS réstant",
  "voice_remaining": "Appels réstant",
  "users": "Utilisateurs",
  "last_login": "Dernière identification",
  "active": "Actif",
  "add_user": "Ajouter un utilisateur",
  "save": "Sauvegarder",
  "password_confirmation": "Confirmez le mot de passe",
  "error_name": "Saisir le nom",
  "error_surname": "Saisir le nom",
  "error_phone_number": "Saisir un numero de telephone",
  "error_email": "Saisir une address email correcte",
  "error_password": "Saisir le mot de passe (Min 8, Min 1 Majuscule, Min 1 Miniscule, Min 1 character special, Min 1 Chiffre)",
  "password_mismatch": "Les mots de passes ne correspondent pas",
  "saved": "Sauvegardé",
  "filter_patient_by": "Filtrer les clients",
  "filter_lost_sight": "Perdu de vue",
  "filter_with_phone": "Avec phone",
  "filter_without_phone": "Sans phone",
  "filter_appointment": "Rendez-vous",
  "filter_fingerprint": "Avec Emprunte digitale",
  "filter_nofingerprint": "Sans Emprunte digitale"
};