import http, {token} from "../../components/instances/http";

export const getCenters = () => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/Main/centers', token())
      .then(response => {
        dispatch({
          type: "SET_CENTERS",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error);
      }); 
  }
}