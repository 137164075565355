const initialState = {
  patient: null,
  patients: [],
  synchData: [],
  fingerprintSummary: []
};

export default function patient (state = initialState, action) {
  switch (action.type) {
    case "SET_PATIENT": 
      return {
        ...state,
        patient: action.payload
      };

      case "SAVE_CUSTOMER": 
      return {
        ...state,
        patient: action.payload
      };
    case "SET_PATIENTS":
      return {
        ...state,
        patients: action.payload.data,
        totalRows: action.payload.totalRecords,
        perPage: action.payload.pageSize,
      };
      case "SET_SYNCH":
        return {
          ...state,
          synchData: action.payload
        };

        case "SET_FINGERPRINT_SUMMARY":
        return {
          ...state,
          fingerprintSummary: action.payload
        };

        case "SET_PDV_SUMMARY":
          return {
            ...state,
            pdvData: action.payload
          };

        
    default:
      return {
        ...state
      };
  }
}
