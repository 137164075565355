const initialState = {
  centers: []
};

export default function center (state = initialState, action) {
  switch (action.type) {
    case "SET_CENTERS":
      return {
        ...state,
        centers: action.payload
      }
    default: 
      return state;
  }
}
