import React from 'react';
import '../../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

function PatientVisit (props) {

  const columns = [
    {
      name: props.t('date'),
      selector: row => row.date,
      sortable: false
    },
    {
      name: props.t('regimen_code'),
      selector: row => row.regimenCode,
      sortable: true
    },
    {
      name: props.t('next_appointment'),
      selector: row => row.nextAppointment,
      sortable: true
    },
    {
      name: props.t('notes'),
      selector: row => row.notes,
      grow: 4
    }
  ]

  return (
    <>
        <h5>Visits</h5>
        <DataTable
          columns={columns}
          data={props.visits}
          pagination
        />
    </>
  )
}

export default (withTranslation() (PatientVisit));
