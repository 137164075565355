import React from 'react';
import '../../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';

function PatientVoiceLog (props) {

  const columns = [
    {
      name: props.t('selection'),
      selector: row => row.selection,
      sortable: true
    },
    {
      name: props.t('ivr_selection'),
      selector: row => row.ivrSelection,
      sortable: true
    },
    {
      name: props.t('success'),
      selector: row => row.success,
      cell: row => <>
                      <span className={row.success ? 'text-success' : 'text-danger'}>
                        <i className={row.success ? 'fa fa-check' : 'fa fa-times'}> </i>
                      </span>
                    </>,
      sortable: true
    },
    {
      name: props.t('date'),
      selector: row => row.callDate,
      sortable: true
    }
  ];

  return (
    <>
      <DataTable
        columns={columns}
        data={props.log}
        pagination
      />
    </>
  )
}

export default (withTranslation() (PatientVoiceLog));
