import React from 'react';
import '../../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import {Bar} from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js'

Chart.register(...registerables)

function SynchNumber (props) {
  var labels = props.data.map(function(v){
        return v.centerName;
  });
  
  var numberOfPatients = props.data.map(function(v){
    return v.numberOfPatients;
  });

  let chartData = {
    labels: labels,
    datasets: [
      {
        label: props.t('patients'),
        backgroundColor: 'rgba(75,192,192,1)',
        borderColor: 'rgba(0,0,0,1)',
        borderWidth: 2,
        data: numberOfPatients
      }
    ]
  };
  const columns = [
    {
      name: props.t('center_name'),
      selector: row => row.centerName,
      sortable: true,
    },
    {
      name: props.t('number_of_patients'),
      selector: row => row.numberOfPatients,
      sortable: true,
    },
    {
      name: props.t('synch_date'),
      selector: row => row.lastSynch,
      sortable: true,
      grow: 2
    }
  ];

  return (
    <>
      <div>
        <Bar
          data={chartData}
          options={{
            title:{
              display:true,
              text:'',
              fontSize:20
            },
            legend:{
              display:true,
              position:'right'
            }
          }}
        />
      </div>
      <DataTable
        columns={columns}
        data={props.data}
        pagination
      />
    </>
  )
}

export default (withTranslation() (SynchNumber));
