import React from 'react';
import '../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { login, otpLogin } from '../../store/actions/auth';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

class Login extends React.Component {
  
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      error_message: null,
      loggedIn: false,
      loading: false,
      otp: false,
      otpNumber: '',
      userId: ''
    }
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }
  async doOtpLogin () {
    this.setState({
      error_message: null,
      loading: true
    });
    await this.props.otpLogin({OTP: this.state.otp, Id: this.state.userId});
    
    if (this.props.loggedIn) {
      this.setState({
        loggedIn: true,
        loading: false
      });
      window.location.replace('/');
    }
    else {
      this.setState({
        error_message: this.props.t('login_error'),
        loading: false
      })
    }
  }

  async doLogin () {
    this.setState({
      error_message: null,
      loading: true
    });
    await this.props.login({email: this.state.email, password: this.state.password});
    
    if (this.props.loggedIn) {
      this.setState({
        loggedIn: true,
        loading: false
      });
      window.location.replace('/');
    } 
    else if(this.props.otp) {
      this.setState({
        loading: false,
        otp: true,
        otpNumber: this.props.otpNumber,
        userId: this.props.userId
      })
    }
    else {
      this.setState({
        error_message: this.props.t('login_error'),
        loading: false
      })
    }
  }

  render () {
    
    return (
      <div className="login-box offset-sm-0 offset-md-5">
        <div className="card card-outline card-primary">
          <div className="card-header text-center">
            <a href="/login" className="h1">
              <img width="250" src='/assets/dist/img/logo.png' alt=''></img>
            </a>
          </div>
          <div className="card-body">
            <p className="login-box-msg">{this.props.t('sign_in')}</p>
            {
              this.state.error_message && (
                <div className="alert alert-danger">
                  {this.state.error_message}
                </div>
              )
            }
            {!this.state.otp && (
            <div className="input-group mb-3">
              <input type="email" name="email" className="form-control" onChange={this.onChange} placeholder={this.props.t('email')} />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-envelope"></span>
                </div>
              </div>
            </div>
            )}
            {!this.state.otp && (
            <div className="input-group mb-3">
              <input type="password" name="password" className="form-control" onChange={this.onChange} placeholder={this.props.t('password')} />
              <div className="input-group-append">
                <div className="input-group-text">
                  <span className="fas fa-lock"></span>
                </div>
              </div>
            </div>
            )}
            {
            this.state.otp && (
              <div className="input-group mb-3">
                <p>{this.props.t('otp_header')} {this.state.otpNumber}</p>
                <input type="otp" name="otp" className="form-control" onChange={this.onChange} placeholder={this.props.t('otp')} />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock"></span>
                  </div>
                </div>
              </div>
            )}
            <div className="social-auth-links text-center mt-2 mb-3">
            {
                this.state.loading && (
                  <div className="text-center">
                    <Spinner animation='grow' />
                  </div>
                )
              }
              {
                !this.state.loading && !this.state.otp && (
              <button className="btn btn-block btn-primary" onClick={() => {this.doLogin()}}>
                {this.props.t('sign_in')}
              </button>
                )}

{
              this.state.otp && (
              <button className="btn btn-block btn-primary" onClick={() => {this.doOtpLogin()}}>
                {this.props.t('verify_otp')}
              </button>
                )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    token: state.authReducer.token,
    otp: state.authReducer.otp,
    role: state.authReducer.role,
    otpNumber: state.authReducer.otpNumber,
    userId: state.authReducer.userId
  }
}

export default connect(mapStateToProps, {login, otpLogin}) ((withTranslation() (Login)));
