import http, {token} from "../../components/instances/http";
import axios from "axios";

const httpDownload = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    responseType: 'blob'
});

export const download = (filename) => {
  return dispatch => {
    return httpDownload.get(process.env.REACT_APP_MAINAPI+'/Main/Download/?filename='+filename, token())
      .then(response => {
        const href = URL.createObjectURL(response.data);
        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      })
      .catch(error => {
        console.log(error);
      }); 
  }
}