import React from 'react';
import { withTranslation } from 'react-i18next';
import '../../../../translations/i18n';
import { connect } from 'react-redux';
import { getPatient } from '../../../../store/actions/patient';
import PatientVisit from './PatientVisit';
import Reminder from '../../Reminder/Reminder';
import SendReminder from '../../Reminder/partials/SendReminder';
import PatientSmsLog from './PatientSmsLog';
import PatientVoiceLog from './PatientVoiceLog';
import Select from 'react-select';
class PatientInformation extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      patient: null
    };
  }

  async componentDidMount () {
    await this.props.getPatient(this.props.id);
    this.setState({
      patient: this.props.patient
    });
  }

  render () {
    return (
      <>
        <div className="content">
        <div className="row">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body">
                  {
                      this.state.patient && (
                        <>
                          <table className="table patient-information">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>
                                      {this.props.t('name')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.name}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('surname')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.surname}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('date_of_birth')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.dob}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('file_number')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.folderNumber}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td>
                                  <strong>
                                  {this.props.t('health_status')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.healthStatus}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('next_appointment')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.nextAppointment}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('last_visit')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.lastVisit}
                                </td>
                              </tr> */}
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('gender')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.gender}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('hiv_outcome')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.hivOutcome}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('resultat')}  : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.outcome}
                                </td>
                              </tr> */}
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('art_start_date')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.artStartDate}
                                </td>
                              </tr> */}
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('phone_number')} : 
                                  </strong>
                                </td>
                                <td>
                                  <input className='frm-group' type='text' value={this.state.patient.phoneNumber}/>
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('alternate_number')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.alternateNumber}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('province')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.province}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('center_name')} : 
                                  </strong>
                                </td>
                                <td>
                                    <Select
                                      options={this.state.centers}
                                      onChange={this.handleCenterChange}
                                      isClearable={true}
                                      defaultInputValue={this.state.patient.centerName}
                                      className='col-md-12'
                                    />
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('active')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.active}
                                </td>
                              </tr>
                              {/* <tr>
                                <td>
                                  <strong>
                                    {this.props.t('has_fingerprint')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.hasFingerPrint === true && (
                                    <span className="text-success">
                                      <i className="fa fa-check"> </i>
                                    </span>
                                  )}
                                  {this.state.patient.hasFingerPrint !== true && (
                                    <span className="text-danger">
                                      <i className="fa fa-times"> </i>
                                    </span>
                                  )}
                                </td>
                              </tr> */}
                              <tr>
                                <td>
                                  <strong>
                                    {this.props.t('address')} : 
                                  </strong>
                                </td>
                                <td>
                                  {this.state.patient.address}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {/* <PatientVisit visits={this.state.patient.visits ?? []} /> */}
                        </>
                      )
                    }
                    <button className='btn pull-right btn-primary'>{this.props.t('save')}</button>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                    {this.state.patient != null && this.state.patient.phoneNumber !=null && this.state.patient.phoneNumber.length > 2 === true && 
                      (
                        <SendReminder patient = {this.state.patient}/>
                      )
                    }
              </div>
              
            </div>
          <div className="container-fluid">
            <div className="row">
            <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h1 className="m-0">{this.props.t('sms_log')}</h1>
                  </div>
                  <div className="card-body">
                    {
                      this.state.patient && (
                        <>
                          <PatientSmsLog
                            log={this.state.patient.smsLog}
                          />
                        </>
                      )
                    }
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <h1 className="m-0">{this.props.t('voice_log')}</h1>
                  </div>
                  <div className="card-body">
                    {
                      this.state.patient && (
                        <PatientVoiceLog
                          log={this.state.patient.voiceLog}
                        />
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
           
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    patient: state.patientReducer.patient
  };
};

export default connect (mapStateToProps, {getPatient}) ((withTranslation() (PatientInformation)));
