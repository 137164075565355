import React from 'react';
import EditForm from './partials/EditForm';
import { useLocation } from 'react-router-dom';

export default function EditUser(props) {
  const { state } = useLocation();
  return (
    <>
      <EditForm user={state.user} />
    </>
  )
}
