import React from 'react';
import {useRef} from 'react';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { withTranslation } from 'react-i18next';
import { getBalance, updateVoiceBalance } from '../../../store/actions/voice';
import { getBalance as getSmsBalance, updateSmsBalance} from '../../../store/actions/sms';
import '../../../translations/i18n';
import { Spinner } from 'react-bootstrap';


class Recharge extends React.Component {
    
    tarrifVoice = 0;
    tarrifSms = 0;

    constructor (props) {
      super (props);
      this.tarrifVoice = parseFloat(process.env.REACT_APP_TARRIF_VOICE);
      this.tarrifSms = parseFloat(process.env.REACT_APP_TARRIF_SMS);
      this.txtVoiceAmount= React.createRef();
      this.txtSmsAmount= React.createRef();
      this.state = {
        voiceBalance: 0,
        smsBalance: 0,
        smsAmount: 0,
        voiceAmount: 0,
        loading: true,
      }
    }

    

    onChange = async (e) => {
      await this.setState({
        [e.target.name]: e.target.value
      });
      if(e.target.name === "smsAmount")
        this.getNumberOfSms(this.state.smsAmount);

      if(e.target.name === "voiceAmount")
        this.getNumberOfVoiceCalls(this.state.voiceAmount);
    }

    rechargeVoice = async() => {
      await this.setState({
        loading: true
      });
      const nCalls = parseFloat(this.state.voiceAmount);
      if(nCalls <= 0 || nCalls > 4000){
        await this.setState({
          loading: false
        });
        alert("Invalid amount (Max 4000)");
        return;
      }
      await this.props.updateVoiceBalance({Amount: nCalls});
      await this.props.getBalance();

      await this.setState({
        voiceBalance: this.props.voiceBalance,
        loading: false
      });

      this.txtVoiceAmount.current.value = '';
    }

    rechargeSms = async() =>  {
      var smses = parseFloat(this.state.smsAmount);
      if(smses <= 0 || smses > 4000){
        await this.setState({
          loading: false
        });
  
        alert("Invalid amount (Max 4000)");
        return;
      }

      await this.setState({
        loading: true
      });
      await this.props.updateSmsBalance({Amount: smses});
      await this.props.getSmsBalance();

      await this.setState({
        smsBalance: this.props.smsBalance,
        loading: false
      });

      this.txtSmsAmount.current.value = ''
    }

    getNumberOfSms(amount){
      let smsAmount = parseFloat(amount);
      this.setState({
        numberOfSms: ((smsAmount - (smsAmount * 0.15))/ this.tarrifSms).toFixed(0)
        
      });
    }
    
    getNumberOfVoiceCalls(amount){
      let voiceAmount = parseFloat(amount);
      this.setState({
        numberOfVoiceCalls: (( voiceAmount - (voiceAmount * 0.15))/ this.tarrifVoice).toFixed(0)
      });
    }


    async componentDidMount () {
      await this.props.getBalance();
      await this.props.getSmsBalance();

      await this.setState({
        voiceBalance: this.props.voiceBalance,
        smsBalance: this.props.smsBalance,
        loading: false
      });
    }
  
    handleSelectDate () {
  
    }
  
  
    render () {
  
      return (
        
          <main>
            {
            this.props.role === 'SuperAdmin' && (
            <div className="content-wrapper">
              <div className="content">
                <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ this.props.t('recharge') }</h1>
                </div>
              </div>
                  <div className="row">
                    <Row >
                      
                    
                      <Col>
                        {
                            this.state.loading && (
                              <>
                                <Spinner animation='grow' />
                              </>
                            )
                          }
                        <div className="card">
                          <div className="card-body">
                          <div className="row">
                          <div className="col">
                          <Form>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Amount</Form.Label>
                              <Form.Control ref={this.txtVoiceAmount} onChange={this.onChange} name="voiceAmount" type="number" />
                              <Form.Text className="text-muted">
                              { this.props.t('amount_in_usd') }
                              </Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Voice Balance</Form.Label>
                              <Form.Control type="number" placeholder="Voice Balance" value= { this.state.voiceBalance } disabled />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Number of Voice Calls</Form.Label>
                              <Form.Control value={this.state.numberOfVoiceCalls} type="number" placeholder="Number of calls" disabled />
                            </Form.Group>
                            <Button variant="primary" type="button" onClick={this.rechargeVoice} disabled={this.state.loading}>
                              Recharge Now
                            </Button>
                          </Form>
                          </div>
                          </div>
                          </div>
                        </div>
                      </Col>
                      <Col>
                        {
                            this.state.loading && (
                              <>
                                <Spinner animation='grow' />
                              </>
                            )
                          }
                        <div className="card">
                          
                          <div className="card-body">
                          <div className="row">
                          <div className="col">
                          <Form>
                            
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                              <Form.Label>Amount</Form.Label>
                              <Form.Control ref={this.txtSmsAmount} onChange={this.onChange} name="smsAmount" type="number" />
                              <Form.Text className="text-muted">
                              { this.props.t('amount_in_usd') }
                              </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Sms Balance</Form.Label>
                              <Form.Control type="number" placeholder="Sms Balance" value={this.state.smsBalance} disabled />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword">
                              <Form.Label>Number of Sms</Form.Label>
                              <Form.Control type="number" placeholder="Number of Sms" value={this.state.numberOfSms}  disabled />
                            </Form.Group>
                            <Button variant="secondary" type="button" onClick={this.rechargeSms} disabled={this.state.loading}>
                              Recharge Now
                            </Button>
                          </Form>
                          </div>
                          </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
              </div>
            ) }
          </main>

      )
    }
  }

  const mapStateToProps = (state) => {
    return {
      smsBalance: state.smsReducer.balance,
      voiceBalance: state.voiceReducer.balance,
      role: state.authReducer.role
    }
  };
  
  export default connect (mapStateToProps, { getBalance, getSmsBalance, updateSmsBalance, updateVoiceBalance }) (withTranslation() (Recharge));
  