import http, { token } from "../../components/instances/http";

export const getVoices = () => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/voice', token())
      .then(response => {
        dispatch({
          type: "SET_VOICES",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error.data);
      });
  };
};

export const sendVoice = (data) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_VOICEAPI+'/voice/CallPatient', data, token())
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "SET_CALL_SENT",
            payload: true
          });
        } else {
          dispatch({
            type: "SET_CALL_SENT",
            payload: false
          });
        }
      })
      .catch (error => {
        console.log(error);
        dispatch({
          type: "SET_CALL_SENT",
          payload: false
        });
      });
  };
};


export const updateVoiceBalance = (balance) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_MAINAPI+'/main/voicerecharge', balance, token())
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "SET_VOICEBALANCE_UPDATED",
            payload: true
          });
        } else {
          dispatch({
            type: "SET_VOICEBALANCE_UPDATED",
            payload: false
          });
        }
      })
      .catch (error => {
        console.log(error);
        dispatch({
          type: "SET_VOICEBALANCE_UPDATED",
          payload: false
        });
      });
  };
}

export const getBalance = () => {
 return dispatch => {
   return http.get(process.env.REACT_APP_MAINAPI+'/main/balance', token())
    .then(response => {
      dispatch({
        type: "SET_VOICE_BALANCE",
        payload: response.data.voice ?? 0
      });
    })
    .catch(error => {
      console.log(error);
    });
 }
};
