const initialState = {
  voices: [],
  sent: false,
  balance: 0
};

export default function voice (state = initialState, action) {
  switch(action.type) {
    case "SET_VOICES":
      return {
        ...state,
        voices: action.payload
      };
    case "SET_CALL_SENT":
      return {
        ...state,
        sent: action.payload
      };
    case "SET_VOICE_BALANCE":
      return {
        ...state,
        balance: action.payload ?? 0
      }
    default:
      return state;
  }
}
