import axios from "axios";
import { toast } from "react-toastify";

const { base64encode } = require('nodejs-base64');

const http = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

http.defaults.headers.common['Accept'] = 'application/json';
http.defaults.headers.common['Content-Type'] = 'application/json';



http.interceptors.response.use(response => {
    return response;
}, error => {
if (error.response.status === 401) {
    localStorage.removeItem('access_token');
    localStorage.removeItem('role');
    window.location.reload();
    toast.error("Could not connect to server");
}
return Promise.reject(error.response);
});

export const token = function getToken(){
  var accessToken = localStorage.getItem('access_token');
  var credentials = base64encode(accessToken);

  return { 
    headers: {
    'Content-Type': 'application/json',
    'Authorization': 'Basic '+credentials
    }
  };
};

export default http;