import React from 'react';
import '../../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { updateUser,getRoles } from '../../../../store/actions/user';
import { connect } from 'react-redux';
import validator from 'validator';
import { toast } from 'react-toastify';
import Select from 'react-select';

class EditUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      password: '',
      phoneNumber: '',
      active: false,
      email: '',
      loading: false,
      errorName: '',
      errorSurname: '',
      errorEmail: '',
      errorPhoneNumber: '',
      role: '',
      roleid: 0,
      roles: null,
      errorPassword: '',
      submit: false,
      password_confirmation: ''
    }
  }

  async componentDidMount() {
    
    await this.props.getRoles();

    var roles = this.props.roles?.map(r => {
      return {label: r.name, value: r.name, id: r.id};
    });

    this.setState({
      name: this.props.user.name,
      surname: this.props.user.surname,
      phoneNumber: this.props.user.phoneNumber,
      email: this.props.user.email,
      role: this.props.user.role,
      roles: roles,
      active: this.props.user.active
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  validateName () {
    this.setState({
      errorName: ''
    });
    if (this.state.name === '') {
      this.setState({
        errorName: this.props.t('error_name')
      });
      return false;
    }

    return true;
  }

  validateSurname () {
    this.setState({
      errorSurname: ''
    });
    if (this.state.surname === '') {
      this.setState({
        errorSurname: this.props.t('error_surname')
      });
      return false;
    }

    return true;
  }

  validateEmail () {
    this.setState({
      errorEmail: ''
    });
    if (! validator.isEmail(this.state.email)) {
      this.setState({
        errorEmail: this.props.t('error_email')
      });
      return false;
    }

    return true;
  }

  validatePhoneNumber () {
    this.setState({
      errorPhoneNumber: ''
    });
    if (this.state.phoneNumber === '') {
      this.setState({
        errorPhoneNumber: this.props.t('error_phone_number')
      });
      return false;
    }

    return true;
  }

  validatePassword () {
    this.setState({
      errorPassword: ''
    });
    if (this.state.password === '' || this.state.password == null) {
      //Password is unchanged
      return true;
    }
    
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, 1 specialchar and one number
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(this.state.password)) {
      this.setState({
        errorPassword: this.props.t('error_password')
      });
      return false;
    }

    if (this.state.password !== this.state.password_confirmation) {
      this.setState({
        errorPassword: this.props.t('password_mismatch')
      });

      return false;
    }
  
    return true;
  }

  saveUser = async () => {
    let submit = this.validatePassword();
    if (submit) {
      this.setState({
        loading: true
      });
      await this.props.updateUser({
        id: this.props.user.id,
        password: (this.state.password === "" || !this.state.password)? null : this.state.password,
        role: this.state.role,
        roleid: this.state.roleid,
        phoneNumber: this.state.phoneNumber,
        active: this.state.active
      });
      if (this.props.status) {
        toast.success(this.props.t('saved'));
      }
      this.setState({
        loading: false
      });
    }
  }

  handleStatusChange = (option) => {
    this.setState({
      active: option.value
    });
  }

  handleRoleChange = (option) => {
    this.setState({
      role: option.value,
      roleid: option.id
    });
  }

  render () {
    const activeStatuses = [{
                              label: 'Active',
                              value: true
                            },
                            {
                              label: 'Inactive',
                              value: false
                            }
                        ];

    return (
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ this.props.t('add_user') }</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="m-0">{ this.props.t('add_user') }</h3>
                    </div>
                    <div className='card-body'>
                      <div className="form-group">
                        <label>{this.props.t('name') }</label>
                        <input name="name" className='form-control' type="text" onChange={this.handleChange} disabled={true} value={this.state.name} />
                        {
                          this.state.errorName !== '' && (
                            <span className="text-danger">{ this.state.errorName }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('surname') }</label>
                        <input name="surname" className='form-control' type="text" value={this.state.surname}  onChange={this.handleChange} disabled={true} />
                        {
                          this.state.errorSurname !== '' && (
                            <span className="text-danger">{ this.state.errorSurname }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('email') }</label>
                        <input name="email" className='form-control' value={this.state.email}  type="text" onChange={this.handleChange} disabled={true} autoComplete="off" />
                        {
                          this.state.errorEmail !== '' && (
                            <span className="text-danger">{ this.state.errorEmail }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('password') }</label>
                        <input name="password" className='form-control' value={this.state.password} type="password" onChange={this.handleChange} disabled={this.loading} autoComplete="off" />
                        {
                          this.state.errorPassword !== '' && (
                            <span className="text-danger">{ this.state.errorPassword }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('password_confirmation') }</label>
                        <input name="password_confirmation" className='form-control' type="password" onChange={this.handleChange} value={this.state.password_confirmation} disabled={this.loading} />
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('phone_number') }</label>
                        <input name="phoneNumber" value={this.state.phoneNumber} className='form-control' type="text" onChange={this.handleChange} disabled={false} />
                        {
                          this.state.errorPhoneNumber !== '' && (
                            <span className="text-danger">{ this.state.errorPhoneNumber }</span>
                          )
                        }
                      </div>
                      <div className='form-group'>
                        <label>{this.props.t('status')}</label>
                        <Select
                          options={activeStatuses}
                          onChange={this.handleStatusChange}
                          value = {
                            activeStatuses.filter(option => 
                               option.value === this.state.active)
                         }
                        />
                      </div>

                      <div className='form-group'>
                        <label>{this.props.t('role')}</label>
                        <Select
                          options={this.state.roles}
                          onChange={this.handleRoleChange}
                          value = {
                            this.state.roles?.filter(option => 
                               option.value === this.state.role)
                         }
                        />
                      </div>
                      <button type='button' className='btn btn-primary' onClick={this.saveUser} disabled={this.loading}>
                        {this.props.t('save')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.userReducer.status,
    roles: state.userReducer.roles
  };
}

export default connect (mapStateToProps, { updateUser, getRoles }) (withTranslation() (EditUser));
