import React from 'react';
import '../../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { Calendar } from 'react-date-range';
import Select from 'react-select';
import { getPatients } from '../../../../store/actions/patient';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { sendVoice } from '../../../../store/actions/voice';
import { sendSms } from '../../../../store/actions/sms';
import { toast } from 'react-toastify';

class SendReminder extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      options: [],
      channel: null,
      loading: false,
      appointmentDate: new Date(),
      patientId: '',
      phoneNumber: ''
    };
  }

  async componentDidMount () {
    this.setState({
      loading: true
    });
    this.setState({
      patientId: this.props.patient.id,
      phoneNumber: this.props.patient.phoneNumber
    });
  }

  handleSelectDate = (date) => {
    this.setState({
      appointmentDate: date
    });
  }

  handleSelectPatient = (option) => {
    
  }

  handleSelectChannel(channel) {
    this.setState({
      channel: channel
    });
  }

  formatPatientsForSelect (patientsObject) {
    let patients = patientsObject.map(patient => {
      return {
        label: patient.name + ' ' + patient.surname + ' | '+patient.folderNumber + '| '+patient.centerName,
        value: patient.id,
        phoneNumber: patient.phoneNumber
      };
    });

    this.setState({
      options: patients
    });
  }

  sendReminder = async () => {
    this.setState({
      loading: true
    });
    if (this.state.patientId === '' || this.state.phoneNumber === '') {
      toast.error(this.props.t('select_a_correct_patient'));
    } else {
      let data = {
        patientId: this.state.patientId,
        phoneNumber: this.state.phoneNumber,
        appointmentDate: this.state.appointmentDate.toLocaleDateString("fr-FR")
      };
  
      if (this.state.channel === "SMS") {
        data.userId = 1;
        data.appointmentDate = this.state.appointmentDate.toISOString().substring(0,10);
        await this.props.sendSms(data);
        if (this.props.sendSmsStatus) {
          toast.success(this.props.t('reminder_sent'));
        } else {
          toast.error(this.props.t('reminder_not_sent'));
        }
      } else if (this.state.channel === "VOICE") {
        await this.props.sendVoice(data);
        if (this.props.sendCallStatus) {
          toast.success(this.props.t('reminder_sent'))
        } else {
          toast.error(this.props.t('reminder_not_sent'));
        }
      } else {
        toast.error(this.props.t('select_correct_channel'));
      }
    }
    
    this.setState({
      loading: false
    });
  }

  render () {
    return (
      <>
        <h5>{this.props.t('send_reminder')}</h5>
        <div className="row">
          <p>{this.props.t('phone_number')}: {this.props.patient.phoneNumber}</p>
          
        </div>
        <div className="row">
          <div className="col-6">
            <Form.Check
              inline
              label="SMS"
              name="channel"
              type="radio"
              id="inline-radio-SMS"
              onChange={() => {this.handleSelectChannel('SMS')}}
              value="SMS"
            />
            <Form.Check
              inline
              label="Voice"
              name="channel"
              type="radio"
              id="inline-radio-Voice"
              onChange={() => {this.handleSelectChannel('VOICE')}}
              value="VOICE"
            />
          </div>
        </div>
        <br/>
        {/* <div className="form-group">
          <label>{this.props.t('select_reminder_date')}</label>
          <Calendar
            date={this.state.appointmentDate}
            onChange={this.handleSelectDate}
          />
        </div> */}
        <button className="btn btn-primary btn-small" onClick={this.sendReminder}>{this.props.t('send_reminder')}</button>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: state.patientReducer.patients,
    sendCallStatus: state.voiceReducer.sent,
    sendSmsStatus: state.smsReducer.sent
  };
};

export default connect (mapStateToProps, { getPatients, sendSms, sendVoice }) (withTranslation() (SendReminder));
