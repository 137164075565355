const initialState = {
  users: [],
  user: null,
  status: false
};

export default function user (state = initialState, action) {
  switch (action.type) {
    case "SET_USERS":
      return {
        ...state,
        users: action.payload
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      };
    case "SET_ROLES":
      return {
        ...state,
        roles: action.payload
      };
      
    case "SAVE_USER":
      return {
        ...state,
        status: action.payload
      };
    default: 
      return state;
  }
}
