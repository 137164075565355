import http, { token } from "../../components/instances/http";
const { base64encode } = require('nodejs-base64');

export const login = (data) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_MAINAPI+'/main/login', data)
      .then(response => {
        
        localStorage.setItem('access_token', response.data.userToken);
        localStorage.setItem('role', response.data.role);

        dispatch({
          type: "SET_OTP_REQUEST",
          payload: {
            phoneNumber: response.data.phoneNumber,
            userId: response.data.id,
            userToken: response.data.userToken,
            otp: response.data.otpRequest,
            otpNumber: response.data.otpNumber
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const otpLogin = (data) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_MAINAPI+'/main/otplogin', data, token())
      .then(response => {
        
        localStorage.setItem('access_token', response.data.userToken);
        localStorage.setItem('role', response.data.role);

        dispatch({
          type: "SET_LOGGED_IN",
          payload: {
            token: response.data.userToken,
            role: response.data.role,
            loggedIn: true
          }
        });
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const logout = () => {
  return dispatch => {
    dispatch ({
      type: "SET_LOGGED_OUT",
      payload: {}
    });
  };
};
