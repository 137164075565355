import React from 'react';
import { withTranslation } from 'react-i18next';
import '../../translations/i18n';
import { Dropdown } from 'react-bootstrap';

class LangSelector extends React.Component {
  
  constructor (props) {
    super (props);

    this.state = {
      locale: 'en'
    };
  }

  async componentDidMount () {
    let locale = await localStorage.getItem('locale') || 'en';
    this.setState({
      locale: locale
    });
  }

  async changeLocale(locale) {
    await localStorage.setItem('locale', locale);
    window.location.reload();
  }

  render () {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle>
            <i className='fa fa-globe'> </i> &nbsp; {this.state.locale}
          </Dropdown.Toggle>
          <Dropdown.Menu>
          <Dropdown.Item onClick={() => {
            this.changeLocale('fr');
          }}>{this.props.t('french')}</Dropdown.Item>
            <Dropdown.Item onClick={() => {
            this.changeLocale('en');
          }}>{this.props.t('english')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  }
}

export default (withTranslation() (LangSelector));
