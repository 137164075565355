import React from 'react';
import '../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false
    }
  }

  async componentDidMount () {
    const loggedIn = await localStorage.getItem('access_token');
    this.setState({
      loggedIn: loggedIn ? true : false
    });
  }

  render () {
    return (
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        <a href="/" className="brand-link">
          <img src="/assets/dist/img/logo.png" alt="Ezra" className="brand-image img-circle elevation-3" />
          <span className="brand-text font-weight-light">Ezra</span>
        </a>
        <div className="sidebar">
          <nav className="mt-2">
            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
            {/* <li className="nav-item">
                <a href="/dashboard" className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p>
                  {this.props.t('dashboard')}
                  </p>
                </a>
              </li> */}
              <li className="nav-item">
                <a href="/customerlist" className="nav-link">
                  <i className="nav-icon fas fa-users"></i>
                  <p>
                  {this.props.t('enduser_list')}
                  </p>
                </a>
              </li>
              {/* <li className="nav-item">
                <a href="/reminder" className="nav-link">
                  <i className="nav-icon fas fa-clock"></i>
                  <p>
                    {this.props.t('reminders')}
                  </p>
                </a>
              </li> */}
              <li className="nav-item">
                <a href="/voice" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                  {this.props.t('voice_log')}
                  </p>
                </a>
              </li>
              <li className="nav-item">
                <a href="/sms" className="nav-link">
                  <i className="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                  {this.props.t('sms_log')}
                  </p>
                </a>
              </li>
              {
                (this.props.role === 'Admin' || this.props.role === 'SuperAdmin') && (
                  <li className="nav-item">
                    <a href="/users" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                      {this.props.t('user_managment')}
                      </p>
                    </a>
                  </li>
                )
              }
              {/* {
                (this.props.role === 'Admin' || this.props.role === 'SuperAdmin') && (
                  <li className="nav-item">
                    <a href="/downloads" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                      {this.props.t('download_apps')}
                      </p>
                    </a>
                  </li>
                )
              } */}
              {
                this.props.role === 'SuperAdmin' && (
                  <li className="nav-item">
                    <a href="/recharge" className="nav-link">
                      <i className="nav-icon fas fa-tachometer-alt"></i>
                      <p>
                      {this.props.t('recharge')}
                      </p>
                    </a>
                  </li>
                )
              }
            </ul>
          </nav>
        </div>
      </aside>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loggedIn: state.authReducer.loggedIn,
    role: state.authReducer.role
  }
}

export default connect(mapStateToProps) ((withTranslation() (SideBar)));
