import http, {token} from "../../components/instances/http";
import { toast } from "react-toastify";

export const getUsers = () => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/users', token())
      .then(response => {
        dispatch({
          type: "SET_USERS",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error);
        toast.error("An error occurred on the server!");
      });
  };
};

export const getUser = (id) => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/users/' + id, token())
      .then(response => {
        dispatch({
          type: "SET_USER",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error);
        toast.error("An error occurred on the server!");
      });
  };
};

export const getRoles = () => {
  return dispatch => {
    return http.get(process.env.REACT_APP_MAINAPI+'/main/roles/', token())
      .then(response => {
        dispatch({
          type: "SET_ROLES",
          payload: response.data
        });
      })
      .catch(error => {
        console.log(error);
        toast.error("An error occurred on the server!");
      });
  };
};


export const storeUser = (data) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_MAINAPI+'/main/users', data, token())
      .then(response => {
        if (response.data.success) {
          dispatch({
            type: "SAVE_USER",
            payload: response.data.success
          });
        } else {
          console.log(response.data)
          toast.error("Could not store at this moment!");
        }
      })
      .catch(error => {
        console.log(error);
        toast.error("An error occurred!");
      });
  };
}

export const updateUser = (data) => {
  return dispatch => {
    return http.post(process.env.REACT_APP_MAINAPI+'/main/users', data, token())
      .then(response => {
        if (response.data.success) {
          dispatch({
            type: "SAVE_USER",
            payload: response.data.success
          });
        } else {
          console.log(response.data)
          toast.error("Could not store at this moment!");
        }
      })
      .catch(error => {
        console.log(error);
        toast.error("An error occurred!");
      });
  };
}
