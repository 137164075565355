import React from 'react';
import '../../../translations/i18n';
import { withTranslation } from 'react-i18next';
import { storeUser, getRoles } from '../../../store/actions/user';
import { connect } from 'react-redux';
import validator from 'validator';
import Select from 'react-select';

class AddUser extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      surname: '',
      password: '',
      phoneNumber: '',
      email: '',
      loading: false,
      errorName: '',
      errorSurname: '',
      errorEmail: '',
      errorPhoneNumber: '',
      errorPassword: '',
      submit: false
    }
  }

  async componentDidMount() {
    
    await this.props.getRoles();

    var roles = this.props.roles?.map(r => {
      return {label: r.name, value: r.name};
    });

    this.setState({
      roles: roles
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    });
  }

  handleRoleChange = (option) => {
    this.setState({
      role: option.value
    });
  }

  validateName () {
    this.setState({
      errorName: ''
    });
    if (this.state.name === '') {
      this.setState({
        errorName: this.props.t('error_name')
      });
      return false;
    }

    return true;
  }

  validateSurname () {
    this.setState({
      errorSurname: ''
    });
    if (this.state.surname === '') {
      this.setState({
        errorSurname: this.props.t('error_surname')
      });
      return false;
    }

    return true;
  }

  validateEmail () {
    this.setState({
      errorEmail: ''
    });
    if (! validator.isEmail(this.state.email)) {
      this.setState({
        errorEmail: this.props.t('error_email')
      });
      return false;
    }

    return true;
  }

  validatePhoneNumber () {
    this.setState({
      errorPhoneNumber: ''
    });
    if (this.state.phoneNumber === '') {
      this.setState({
        errorPhoneNumber: this.props.t('error_phone_number')
      });
      return false;
    }

    return true;
  }

  validatePassword () {
    this.setState({
      errorPassword: ''
    });
    //Minimum eight characters, at least one uppercase letter, one lowercase letter, 1 special char and one number
    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!regex.test(this.state.password)) {
      this.setState({
        errorPassword: this.props.t('error_password')
      });
      return false;
    }

    if (this.state.password !== this.state.password_confirmation) {
      this.setState({
        errorPassword: this.props.t('password_mismatch')
      });

      return false;
    }

    return true;
  }

  saveUser = async () => {
    let submit = this.validateName() & this.validateSurname() & this.validatePhoneNumber() & this.validateEmail() & this.validatePassword();
    if (submit) {
      this.setState({
        loading: true
      });
      await this.props.storeUser({
        name: this.state.name,
        surname: this.state.surname,
        password: this.state.password,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        role: this.state.role,
        active: true
      });
      if (this.props.status) {
        window.location.replace('/users');
      }
      this.setState({
        loading: false
      });
    }
  }

  render () {
    return (
      <main>
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">{ this.props.t('add_user') }</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="m-0">{ this.props.t('add_user') }</h3>
                    </div>
                    <div className='card-body'>
                      <div className="form-group">
                        <label>{this.props.t('name') }</label>
                        <input name="name" className='form-control' type="text" onChange={this.handleChange} disabled={this.loading} />
                        {
                          this.state.errorName !== '' && (
                            <span className="text-danger">{ this.state.errorName }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('surname') }</label>
                        <input name="surname" className='form-control' type="text" onChange={this.handleChange} disabled={this.loading} />
                        {
                          this.state.errorSurname !== '' && (
                            <span className="text-danger">{ this.state.errorSurname }</span>
                          )
                        }
                      </div>
                      
                      
                      <div className="form-group">
                        <label>{this.props.t('email') }</label>
                        <input name="email" className='form-control' value={""} type="text" onChange={this.handleChange} disabled={this.loading} autocomplete="off"/>
                        {
                          this.state.errorEmail !== '' && (
                            <span className="text-danger">{ this.state.errorEmail }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('password') }</label>
                        <input name="password" value={""} className='form-control' type="password" onChange={this.handleChange} disabled={this.loading} autocomplete="off" />
                        {
                          this.state.errorPassword !== '' && (
                            <span className="text-danger">{ this.state.errorPassword }</span>
                          )
                        }
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('password_confirmation') }</label>
                        <input name="password_confirmation" className='form-control' type="password" onChange={this.handleChange} disabled={this.loading} />
                      </div>
                      <div className="form-group">
                        <label>{this.props.t('phone_number') }</label>
                        <input name="phoneNumber" className='form-control' type="text" onChange={this.handleChange} disabled={this.loading} />
                        {
                          this.state.errorPhoneNumber !== '' && (
                            <span className="text-danger">{ this.state.errorPhoneNumber }</span>
                          )
                        }
                      </div>
                      <div className='form-group'>
                        <label>{this.props.t('role')}</label>
                        <Select
                          options={this.state.roles}
                          onChange={this.handleRoleChange}
                        />
                      </div>
                      <button type='button' className='btn btn-primary' onClick={this.saveUser} disabled={this.loading}>
                        {this.props.t('save')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    status: state.userReducer.status,
    roles: state.userReducer.roles
  };
}

export default connect (mapStateToProps, { storeUser, getRoles }) (withTranslation() (AddUser));
