import React from 'react';
import { withTranslation } from 'react-i18next';
import '../../../translations/i18n';
import { connect } from 'react-redux';
import { getSynchData, getFingerprintData, getPdvData } from '../../../store/actions/patient';
import { Spinner } from 'react-bootstrap';
import SynchNumber from '../Patient/partials/SynchNumber';

class Dashboard extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      synchData: null,
      fingerprintSummary: null,
      loading: true
    };
  }

  async componentDidMount () {
    await this.props.getSynchData();
    await this.props.getFingerprintData();
    await this.props.getPdvData();
    this.setState({
        synchData: this.props.synchData,
        fingerprintSummary: this.props.fingerprintSummary,
        pdvData: this.props.pdvData,
        loading: false
    });
  }

  render () {
    return (
      <>
      <main>
        <div className="content-wrapper">
        <div className="container-fluid">
         <div className="row">
              <div className="col-md-6">
                    <div className="card">
                    <div className="card-header">
                    {this.props.t('dashboard_synch')}
                    </div>
                    <div className="card-body">
                  {
                          this.state.loading && (
                            <div className="text-center">
                              <p>Please wait while we fetching the data</p>
                              <Spinner animation='grow' />
                            </div>
                          )
                        }
                    {
                      this.state.synchData && (
                        <>

                          <SynchNumber
                            data={this.state.synchData}
                          />
                        </>
                      )
                    }

                    
                  </div>
                </div>
              </div>
              <div className="col-md-6">
              <div className="card">
                    <div className="card-header">
                    {this.props.t('dashboard_fingerprint_summary')}
                    </div>
                    <div className="card-body">
                  {
                          this.state.loading && (
                            <div className="text-center">
                              <p>Please wait while we fetching the data</p>
                              <Spinner animation='grow' />
                            </div>
                          )
                        }

                    {
                      this.state.fingerprintSummary && (
                        <>

                          <SynchNumber
                            data={this.state.fingerprintSummary}
                          />
                        </>
                      )
                    }
                  </div>
                </div>
              </div>

              <div className="col-md-6">
              <div className="card">
                    <div className="card-header">
                    {this.props.t('dashboard_pdvData_summary')}
                    </div>
                    <div className="card-body">
                  {
                          this.state.loading && (
                            <div className="text-center">
                              <p>Please wait while we fetching the data</p>
                              <Spinner animation='grow' />
                            </div>
                          )
                        }

                    {
                      this.state.pdvData && (
                        <>

                          <SynchNumber
                            data={this.state.pdvData}
                          />
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
              
            </div>
          
        </div>
        </div>
        </main>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    synchData: state.patientReducer.synchData,
    fingerprintSummary: state.patientReducer.fingerprintSummary,
    pdvData: state.patientReducer.pdvData
  };
};

export default connect (mapStateToProps, {getSynchData, getFingerprintData, getPdvData}) ((withTranslation() (Dashboard)));
